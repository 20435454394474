import * as actions from "../actions/actionTypes";

const initState = {
  actionFinished: false,
  stripeInfo: null,
  actionType: "",
  error: null,
};
// HELPER FUNCTIONS

const cleanUp = (state) => {
  return {
    ...state,
    actionStarting: false,
    actionType: "",
    error: null,
  };
};

const stripReducer = (state = initState, action) => {
  switch (action.type) {
    case actions.CLEAN_UP:
      return cleanUp(state);

    case "CREATE_PAYMENT_METHOD_SUCCESS":
      return {
        ...state,
        actionFinished: true,
        actionType: "create_payment_method",
        error: "",
      };
    case "CREATE_PAYMENT_METHOD_ERROR":
      return {
        ...state,
        actionFinished: true,
        actionType: "create_payment_method",
        error: action.payload,
      };

    case "READ_STRIPE_INFO_SUCCESS":
      return {
        ...state,
        actionFinished: true,
        actionType: "read_stripe_info",
        stripeInfo: action.payload,
        error: "",
      };
    case "READ_STRIPE_INFO_ERROR":
      return {
        ...state,
        actionFinished: true,
        actionType: "read_stripe_info",
        stripeInfo: null,
        error: action.payload,
      };
    case "RESET_ACTION_STRIPE":
      return {
        ...state,
        actionFinished: false,
        actionType: "",
        error: "",
      };
    default:
      return state;
  }
};

export default stripReducer;
