import * as actions from "../actions/actionTypes";

const initState = {
  redirectUrl: "/",
  authError: null,
  actionFinished: false,
  actionType: "",

  profile: {
    error: null,
    info: null,
  },
  verifyEmail: {
    error: null,
    loading: false,
  },
  verifyEmailSend: {
    error: null,
    loading: false,
  },
};
// HELPER FUNCTIONS

const verifyStart = (state) => {
  return {
    ...state,
    verifyEmail: { ...state.verifyEmail, loading: true },
  };
};

const verifySuccess = (state) => {
  return {
    ...state,
    verifyEmail: { ...state.verifyEmail, loading: false, error: false },
  };
};

const verifyFail = (state, payload) => {
  return {
    ...state,
    verifyEmail: { ...state.verifyEmail, loading: false, error: payload },
  };
};

const verifyEmailSendStart = (state) => {
  return {
    ...state,
    verifyEmailSend: { ...state.verifyEmailSend, loading: true },
  };
};

const verifyEmailSendSuccess = (state) => {
  return {
    ...state,
    verifyEmailSend: { ...state.verifyEmailSend, loading: false, error: false },
  };
};

const verifyEmailSendFail = (state, payload) => {
  return {
    ...state,
    verifyEmailSend: {
      ...state.verifyEmailSend,
      loading: false,
      error: payload,
    },
  };
};

const cleanUp = (state) => {
  return {
    ...state,
    // redirectUrl: "/",
    actionStarting: false,
    actionType: "",
    authError: null,
    profile: {
      error: null,
      info: null,
    },
    verifyEmail: {
      error: null,
      loading: false,
    },
    verifyEmailSend: {
      error: null,
      loading: false,
    },
  };
};

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case actions.CLEAN_UP:
      return cleanUp(state);
    case "SET_REDIRECT_URL":
      // console.log("setredicet");
      return {
        ...state,
        redirectUrl: action.payload,
      };
    case "LOGIN_ERROR":
      // console.log(action);
      return {
        ...state,
        authError: action.err.message //"Login failed",
      };

    case "LOGIN_SUCCESS":
      // console.log("login success");
      return {
        ...state,
        authError: null,
      };

    case "SIGNOUT_SUCCESS":
      // console.log("signout success");
      return state;

    case "SIGNUP_SUCCESS":
      // console.log("signup success");
      return {
        ...state,
        actionType : "signup",
        authError: null,
      };

    case "SIGNUP_ERROR":
      // console.log("signup error");
      return {
        ...state,
        authError: action.err.message,
      };
    case "SIGNUP_WITH_REQUEST_CLEAR":
      // console.log("signup success");
      return {
        ...state,
        actionType : "",
        authError: null,
      };
    case "SIGNUP_WITH_REQUEST_SUCCESS":
      return {
        ...state,
        actionType : "signupWithRequest",
        authError: null,
      };

    case "SIGNUP_WITH_REQUEST_ERROR":
      return {
        ...state,
        actionType : "signupWithRequest",
        authError: action.error.message,
      };
    case "SIGNUP_WITH_GOOGLE_AUTH_SUCCESS":
      return {
        ...state,
        actionType : "signupWithRequest",
        authError: null,
      };

    case "SIGNUP_WITH_GOOGLE_AUTH_ERROR":
      return {
        ...state,
        actionType : "signupWithRequest",
        authError: action.error.message,
      };
    case "SIGNIN_WITH_REQUEST_SUCCESS":
      return {
        ...state,
        actionType : "signinWithRequest",
        authError: null,
      };

    case "SIGNIN_WITH_REQUEST_ERROR":
      // console.log("signup error");
      return {
        ...state,
        actionType : "signinWithRequest",
        authError: action.error.message,
      };

    case actions.VERIFY_START:
      return verifyStart(state);

    case actions.VERIFY_SUCCESS:
      return verifySuccess(state);

    case actions.VERIFY_FAIL:
      return verifyFail(state, action.payload);

    case actions.VERIFY_EMAIL_SEND_SUCCESS:
      return verifyEmailSendSuccess(state);

    case actions.VERIFY_EMAIL_SEND_START:
      return verifyEmailSendStart(state);

    case actions.VERIFY_EMAIL_SEND_FAIL:
      return verifyEmailSendFail(state, action.payload);

    case "READ_PROFILE_SUCCESS":
      return {
        ...state,
        profile: {
          info: action.payload,
          error: null,
        },
      };

    case "READ_PROFILE_ERR":
      return {
        ...state,
        profile: {
          info: null,
          error: action.payload,
        },
      };

    case "RESET_ACTION_AUTH":
      return {
        ...state,
        actionFinished: false,
        actionType: "",
        authError: "",
      };

    case "CHANGE_USER_CONTACT_SUCCESS":
      return {
        ...state,
        actionFinished: true,
        actionType: "updateUserContact",
        authError: "",
      };
    case "CHANGE_USER_CONTACT_FAIL":
      return {
        ...state,
        actionFinished: true,
        actionType: "updateUserContact",
        authError: action.payload,
      };

    default:
      return state;
  }
};

export default authReducer;
