import * as actions from "../actions/actionTypes";

const initState = {
  myTutors: [],
  myLessons: [],
  myRequests: [],
  myTransactions: [],
  myQuestions: [],
  studentInfor: null,
  searchCondition: null,
  searchConditionPageInfo: null,
  requestTutor: null,
  requestLesson: null,
  searching: false,
  error: "",
  redirectUrl: "",
};
// HELPER FUNCTIONS

const cleanUp = (state) => {
  return {
    myTutors: [],
  myLessons: [],
  myRequests: [],
  myTransactions: [],
  myQuestions: [],
  studentInfor: null,
  searchCondition: null,
  searchConditionPageInfo: null,
  requestTutor: null,
  requestLesson: null,
  searching: false,
  error: "",
  redirectUrl: "",
  };
};

const studentReducer = (state = initState, action) => {
  switch (action.type) {
    case actions.CLEAN_UP:
      return cleanUp(state);

    case "START_SEARCH_STUDENT":
      return {
        ...state,
        searching: true,
      };

    case "SET_STUDENT_INFO_ERROR":
      return {
        studentInfor: null,
        error: "Save failed",
        actionType: "set_student_info_error",
      };

    case "SET_STUDENT_INFO_SUCCESS":
      return {
        ...state,
        error: "",
        actionType: "set_student_info_success",
        studentInfor: {
          ...state.studentInfor,
          ...action.payload,
        },
      };
    case "UPDATE_STUDENT_INFO_ERROR":
      return {
        studentInfor: null,
        error: "Save failed",
      };

    case "UPDATE_STUDENT_INFO_SUCCESS":
      return {
        ...state,
        error: "",
        studentInfor: action.payload,
      };
    case "READ_STUDENT_INFO_ERROR":
      return {
        studentInfor: null,
        error: action.payload,
      };

    case "READ_STUDENT_INFO_SUCCESS":
      return {
        ...state,
        error: "",
        studentInfor: action.payload,
      };
    case "UPDATE_MY_TUTORS_ERROR":
      return {
        ...state,
        actionType: "update_mytutors",
        error: action.payload,
      };
    case "UPDATE_MY_TUTORS_SUCCESS":
      return {
        ...state,
        actionType: "update_mytutors",
        error: "",
      };
    case "READ_MY_TUTORS_ERROR":
      return {
        myTutors: [],
        error: action.payload,
      };

    case "READ_MY_TUTORS_SUCCESS":
      return {
        ...state,
        error: "",
        myTutors: action.payload,
      };

    case "READ_MY_LESSONS_SUCCESS":
      return {
        ...state,
        error: "",
        searching: false,
        myLessons: action.payload,
      };
    case "READ_MY_LESSONS_ERROR":
      return {
        ...state,
        myLessons: [],
        searching: false,
        error: action.payload,
      };
    case "READ_MY_QUESTIONS_SUCCESS":
      return {
        ...state,
        error: "",
        searching: false,
        myQuestions: action.payload.questions,
        questionCount: action.payload.questionCount,
      };

    case "READ_MY_QUESTIONS_ERROR":
      return {
        ...state,
        searching: false,
        error: action.payload,
        myQuestions: [],
        questionCount: 0,
      };

    case "READ_MY_REQUEST_SUCCESS":
      return {
        ...state,
        error: "",
        actionType: "read_my_request",
        myRequests: action.payload,
      };
    case "READ_MY_REQUEST_ERROR":
      return {
        ...state,
        myRequests: [],
        actionType: "read_my_request",
        error: action.payload,
      };
    
    case "APPROVE_SUBMIT_ANSWER_SUCCESS":
      return {
        ...state,
        actionType: "approved_submit_answer",
        error: false,
      };
  
    case "APPROVE_SUBMIT_ANSWER_ERROR":
      return {
        ...state,
        actionType: "approved_submit_answer",
        error: true,
      };

    case "READ_MY_TRANSACTION_SUCCESS":
      return {
        ...state,
        error: "",
        searching: false,
        myTransactions: action.payload,
      };
    case "READ_MY_TRANSACTION_ERROR":
      return {
        myTransactions: [],
        searching: false,
        error: action.payload,
      };

    case "SEARCH_CONDITION":
      return {
        ...state,
        searchCondition: action.payload,
      };

    case "SEARCH_CONDITION_PAGE_INFO":
      return {
        ...state,
        searchConditionPageInfo: action.payload,
      };
    case "SET_REQUEST_SUCCESS":
      return {
        ...state,
        requestTutor: action.payload,
        error: "",
      };
    case "SET_REQUEST_INFO_ERROR":
      return {
        ...state,
        requestTutor: null,
        error: action.err,
      };

    case "SET_REQUEST_HELP_SUCCESS":
      return {
        ...state,
        actionType: "set_request_help_success",
        error: "",
      };
    case "SET_REQUEST_HELP_ERROR":
      return {
        ...state,
        actionType: "set_request_help_error",
        error: action.err,
      };
  
    case "SIGNUP_WITH_GOOGLE_AUTH_FOR_STUDENT_SUCCESS":
      return {
        ...state,
        actionType: "set_request_with_google_auth",
        error: "",
      };
    case "SIGNUP_WITH_GOOGLE_AUTH_FOR_STUDENT_ERROR":
      return {
        ...state,
        actionType: "set_request_with_google_auth",
        error: action.err,
      };

    case "SET_REQUEST_LESSON_SUCCESS":
      return {
        ...state,
        requestLesson: action.payload,
        error: "",
      };
    case "SET_REQUEST_LESSON_ERROR":
      return {
        ...state,
        requestLesson: null,
        error: action.err,
      };

    case "APPLY_SUBMIT_LESSON_SUCCESS":
      return {
        ...state,
        starting: false,
        actionType: "apply_submit_lesson",
        error: "",
      };
    case "APPLY_SUBMIT_LESSON_ERROR":
      return {
        ...state,
        starting: false,
        actionType: "apply_submit_lesson",
        error: action.payload,
      };
    case "SET_REQUEST_INTERVIEW_SUCCESS":
      return {
        ...state,
        actionType: "request_interview",
        error: "",
      };
    case "SET_REQUEST_INTERVIEW_ERROR":
      return {
        ...state,
        actionType: "request_interview",
        error: action.payload,
      };

    case "SET_REDIRECTION_URL":
      return {
        ...state,
        redirectUrl: action.payload,
      };

    case "RESET_ACTION_STUDENT":
      return {
        ...state,
        actionType: "",
        // searchCondition: null,
        // studentInfor: null,
        starting: false,
        error: "",
        redirectUrl: "",
      };
    
    case "RESET_ACTION_STUDENT_FOR_SEARCH_TUTOR":
      return {
        ...state,
        actionType: "",
        searchCondition: null,
        searchConditionPageInfo: null,
        starting: false,
        error: "",
        redirectUrl: "",
      };
    default:
      return state;
  }
};

export default studentReducer;
