import firebase from "firebase/app";
import "firebase/storage";
import "firebase/database";
import "firebase/firestore";
import "firebase/auth";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDgLlUkQgMpm7Fs4FjDHPyRtwF5V_s0uBw",
  authDomain: "tutorcarter-dev.firebaseapp.com",
  databaseURL: "https://tutorcarter-dev.firebaseio.com",
  projectId: "tutorcarter-dev",
  storageBucket: "tutorcarter-dev.appspot.com",
  messagingSenderId: "546915103099",
  appId: "1:546915103099:web:c85818b2b6dd5b43fdcdde",
  measurementId: "G-Y762WDGEZ9"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.firestore();
firebase.database();
const storage = firebase.storage();
export { storage, firebase as default };
