const initState = {
  searchCondition: null,
  searchConditionPageInfo: null,
  error: null,
  starting: false,
  message: "",
  loading: false,
  postList: []
};
// HELPER FUNCTIONS

const postsReducer = (state = initState, action) => {
  switch (action.type) { 
    case "SEARCH_POSTS_CONDITION":
      return {
        ...state,
        searchCondition: action.payload,
        subjects: action.subjects,
      };
      case "SEARCH_POSTS_CONDITION_PAGE_INFO":
        return {
          ...state,
          searchConditionPageInfo: action.payload,
        };
    case "RESET_POSTS_STATE":
      return {
        ...state,
        error: null,
        starting: false,
        message: "",
        loading: false,
      };
    case "READ_POST_LIST_SUCCESS":
      return {
        ...state,
        postList : action.payload,
      }
    default:
      return state;
  }
};

export default postsReducer;
