import * as actions from "../actions/actionTypes";

const initState = {
  myStudents: [],
  myAnswers: [],
  mySchedules: [],
  myScheduleLessons: [],
  reqLessons: [],
  tutorInfor: null,
  error: null,
  starting: false,
  message: "",
  actionType: "",
  loading: false,
};
// HELPER FUNCTIONS

const cleanUp = () => {
  return {
    myStudents: [],
    myAnswers: [],
    mySchedules: [],
    myScheduleLessons: [],
    reqLessons: [],
    tutorInfor: null,
    error: null,
    starting: false,
    message: "",
    actionType: "",
    loading: false,
  };
};

const tutorReducer = (state = initState, action) => {
  switch (action.type) {
    case actions.CLEAN_UP:
      return cleanUp();

    case "SET_TUTOR_INFO_ERROR":
      return {
        ...state,
        tutorInfor: null,
        starting: false,
        error: true,
        actionType: "create",
        message: action.payload,
      };

    case "SET_TUTOR_INFO_SUCCESS":
      return {
        ...state,
        tutorInfor: action.payload,
        starting: false,
        error: false,
        actionType: "create",
        message: "",
      };
    case "UPDATE_TUTOR_INFO_SUCCESS":
      return {
        ...state,
        starting: false,
        actionType: "update",
        error: false,
        message: "",
      };
    case "UPDATE_TUTOR_INFO_ERROR":
      return {
        ...state,
        starting: false,
        actionType: "update",
        error: true,
        message: "Update is fail!",
      };
    case "SEND_APPLICATION_SUCCESS":
      return {
        ...state,
        starting: false,
        actionType: "send_application",
        error: false,
        message: "",
      };
    case "SEND_APPLICATION_ERROR":
      return {
        ...state,
        starting: false,
        actionType: "send_application",
        error: true,
        message: action.payload,
      };

    case "SEND_PROPOSAL_QUESTION_SUCCESS":
      return {
        ...state,
        starting: false,
        actionType: "send_proposal_question",
        error: false,
        message: "",
      };
    case "SEND_PROPOSAL_QUESTION_ERROR":
      return {
        ...state,
        starting: false,
        actionType: "send_proposal_question",
        error: true,
        message: action.payload,
      };
    case "READ_TUTOR_INFO_SUCCESS":
      return {
        ...state,
        tutorInfor: action.payload,
        actionType: "read_tutorinfo",
        starting: false,
        error: false,
        message: "",
      };

    case "READ_TUTOR_INFO_ERR":
      return {
        ...state,
        tutorInfor: null,
        actionType: "read_tutorinfo",
        starting: false,
        error: true,
        message: action.payload,
      };

    case "READ_MY_STUDENTS_ERROR":
      return {
        myStudents: [],
        error: true,
        message: action.payload,
      };

    case "READ_MY_STUDENTS_SUCCESS":
      return {
        ...state,
        error: false,
        message: "",
        starting: false,
        actionType: "read_mystudents",
        myStudents: action.payload,
      };

    case "READ_MY_SCHEDULE_ERROR":
      return {
        ...state,
        mySchedules: [],
        error: true,
        message: action.payload,
      };

    case "READ_MY_SCHEDULE_SUCCESS":
      return {
        ...state,
        error: false,
        message: "",
        mySchedules: action.payload,
      };

    case "READ_MY_ANSWERS_SUCCESS":
      return {
        ...state,
        error: false,
        message: "",
        starting: false,
        myAnswers: action.payload.answers,
        answerCount: action.payload.answerCount,
      };

    case "READ_MY_ANSWERS_ERROR":
      return {
        ...state,
        error: true,
        starting: false,
        myAnswers: [],
        message: action.payload,
      };

    case "READ_MY_SCHEDULE_LESSONS_ERROR":
      return {
        ...state,
        myScheduleLessons: [],
        error: true,
        starting: false,
        message: action.payload,
      };

    case "READ_MY_SCHEDULE_LESSONS_SUCCESS":
      return {
        ...state,
        error: false,
        message: "",
        starting: false,
        myScheduleLessons: action.payload.scheduleLessons,
        lessonCount: action.payload.lessonCount,
      };

    case "READ_REQUEST_LESSONS_ERROR":
      return {
        ...state,
        reqLessons: [],
        error: true,
        message: action.payload,
      };

    case "READ_REQUEST_LESSONS_SUCCESS":
      return {
        ...state,
        error: false,
        message: "",
        reqLessons: action.payload,
      };

    case "SAVE_SCHEDULE_SUCCESS":
      return {
        ...state,
        starting: false,
        actionType: "save_schedule",
        error: false,
        message: "",
      };
    case "SAVE_SCHEDULE_ERROR":
      return {
        ...state,
        starting: false,
        actionType: "save_schedule",
        error: true,
        message: action.payload,
      };

    case "UPDATE_MY_STUDENTS_SUCCESS":
      return {
        ...state,
        starting: false,
        actionType: "update_students",
        error: false,
        message: "",
      };
    case "UPDATE_MY_STUDENTS_ERROR":
      return {
        ...state,
        starting: false,
        actionType: "update_students",
        error: true,
        message: action.payload,
      };
    case "UPDATE_MY_LESSON_SUCCESS":
      return {
        ...state,
        starting: false,
        actionType: "update_lesson",
        error: false,
        message: "",
      };
    case "UPDATE_MY_LESSON_ERROR":
      return {
        ...state,
        starting: false,
        actionType: "update_lesson",
        error: true,
        message: action.payload,
      };
    case "SEND_SUBMIT_LESSON_SUCCESS":
      return {
        ...state,
        starting: false,
        actionType: "send_submit_lesson",
        error: false,
        message: "",
      };
    case "SEND_SUBMIT_LESSON_ERROR":
      return {
        ...state,
        starting: false,
        actionType: "send_submit_lesson",
        error: true,
        message: action.payload,
      };

    case "ANSWER_QUESTION_SUCCESS":
      return {
        ...state,
        error: false,
        message: "",
        starting: false,
        actionType: "answer_question",
      };

    case "ANSWER_QUESTION_ERROR":
      return {
        ...state,
        starting: false,
        actionType: "answer_question",
        error: true,
        message: action.payload,
      };

    case "SEND_SUBMIT_ANSWER_SUCCESS":
      return {
        ...state,
        starting: false,
        actionType: "send_submit_answer",
        error: false,
        message: "",
      };
    case "SEND_SUBMIT_ANSWER_ERROR":
      return {
        ...state,
        starting: false,
        actionType: "send_submit_answer",
        error: true,
        message: action.payload,
      };

    case "START_ACTION_TUTOR":
      return {
        ...state,
        starting: true,
        actionType: "",
      };

    case "RESET_ACTION_TUTOR":
      return {
        ...state,
        actionType: "",
        starting: false,
      };
    case "END_ACTION_TUTOR":
      return {
        ...state,
        starting: false,
      };
    case "INIT_STATE_TUTOR":
      return {
        ...state,
        error: null,
        updateInforState: false,
        message: "",
      };
    default:
      return state;
  }
};

export default tutorReducer;
