const initState = {
  searchCondition: null,
  searchConditionPageInfo: null,
  subjects: null,
  error: null,
  starting: false,
  message: "",
  loading: false,
};
// HELPER FUNCTIONS

const jobsReducer = (state = initState, action) => {
  switch (action.type) { 
    case "SEARCH_JOBS_CONDITION":
      return {
        ...state,
        searchCondition: action.payload,
        subjects: action.subjects,
      };
      case "SEARCH_JOBS_CONDITION_PAGE_INFO":
        return {
          ...state,
          searchConditionPageInfo: action.payload,
        };
    case "RESET_JOB_STATE":
      return {
        ...state,
        error: null,
        starting: false,
        message: "",
        loading: false,
      };
    default:
      return state;
  }
};

export default jobsReducer;
