const initState = {
  requestLessonDlgOpen: false,
  proposeLessonDlgOpen : false,
};
// HELPER FUNCTIONS

const chatReducer = (state = initState, action) => {
  switch (action.type) { 
    case "CHAT_REQUEST_LESSON_OPEN":
      return {
        ...state,
        requestLessonDlgOpen: true,
      };
    case "CHAT_REQUEST_LESSON_CLOSE":
      return {
        ...state,
        requestLessonDlgOpen: false,
      };
    case "CHAT_PROPOSE_LESSON_OPEN":
      return {
        ...state,
        proposeLessonDlgOpen: true,
      };
    case "CHAT_PROPOSE_LESSON_CLOSE":
      return {
        ...state,
        proposeLessonDlgOpen: false,
      };
   
    default:
      return state;
  }
};

export default chatReducer;
