import authReducer from "./authReducer";
import { combineReducers } from "redux";
import { firestoreReducer } from "redux-firestore";
import { firebaseReducer } from "react-redux-firebase";
import studentReducer from "./studentReducer";
import tutorReducer from "./tutorReducer";
import uploadReducer from "./uploadReducer";
import jobsReducer from "./jobsReducer";
import postsReducer from "./postsReducer";
import questionsReducer from "./questionsReducer";
import stripReducer from "./stripReducer";
import chatReducer from "./chatReducer";
import notificationReducer from "./notificationReducer";
const rootReducer = combineReducers({
  auth: authReducer,
  student: studentReducer,
  tutor: tutorReducer,
  firestore: firestoreReducer,
  firebase: firebaseReducer,
  upload: uploadReducer,
  jobs: jobsReducer,
  posts: postsReducer,
  questions: questionsReducer,
  chat: chatReducer,
  notify: notificationReducer,
  strip:stripReducer
});

export default rootReducer;

