import React, { lazy, Suspense } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { ThemeProvider } from "@material-ui/styles";
import theme from "./theme";
import MetaDecorator from "./components/MetaDecorator";
import ScrollTopButton from "./components/ScrollTopButton";
import { useFirebase } from "react-redux-firebase";
import { useSelector, } from "react-redux";

const Navbar = lazy(() => import("./views/layout/Navbar"));

const Footer = lazy(() => import("./views/layout/Footer"));

const Home = lazy(() => import("./views/Home/Home"));

const SignUp = lazy(() => import("./views/auth/SignUp"));

const SelectAccountType = lazy(() => import("./views/auth/SelectAccountType"));

const SignIn = lazy(() => import("./views/auth/SignIn"));

const EndSignUp = lazy(() => import("./views/auth/EndSignUp"));

const ForgetPw = lazy(() => import("./views/auth/ForgetPassword"));

const Verify = lazy(() => import("./views/auth/VerificationFirebase"));

const StudentVerify = lazy(() => import("./views/auth/VerifyStudent"));

const TutorVerify = lazy(() => import("./views/auth/VerifyTutor"));

const ResetPw = lazy(() => import("./views/auth/ResetPassword"));

const TutorLp = lazy(() => import("./views/auth/SignUpPPCLanding"));

const SearchLp = lazy(() => import("./views/Home/Search"));

const HowItWork = lazy(() => import("./views/help/Howitwork"));

const HowItWorkStudent = lazy(() => import("./views/help/HowitworkStudent"));

const HowItWorkTutor = lazy(() => import("./views/help/HowItWorkTutor"));

const Terms = lazy(() => import("./views/help/terms/Terms"));

const AboutUs = lazy(() => import("./views/help/AboutUs"));

const Privacy = lazy(() => import("./views/help/Privacy"));

const ContactUs = lazy(() => import("./views/help/ContactUs"));

const Posts = lazy(() => import("./views/blogs/Blogs"));

const PostView = lazy(() => import("./views/blogs/components/Blog"));

const ErrorPage = lazy(() => import("./views/layout/NoMatch"));

const DashboardStudent = lazy(() => import("./views/student/dashboard/DashboardStudent"));

const SearchTutor = lazy(() => import("./views/student/search/SearchTutor"));

const TutorDetail = lazy(() => import("./views/student/search/tutor/Tutor"));

const SettingInforStudent = lazy(() => import("./views/student/settingInfor/SettingInforStudent"));

const PrivateRequestTutor = lazy(() => import("./views/student/requestTutor/RequestTutor"));

const RequestHelp = lazy(() => import("./views/student/requestHelp/RequestHelp"));

const RequestHelpWithSignin = lazy(() => import("./views/student/requestHelp/RequestHelpWithSignin"));

const RequestHelpFinish = lazy(() => import("./views/student/requestHelp/RequestHelpFinish"));

const RequestTutor = lazy(() => import("./views/student/request/RequestTutor"));

const RequestTutorFinish = lazy(() => import("./views/student/request/RequestTutorFinish"));

const chatStudent = lazy(() => import("./views/student/messages"));

const PaymentInfoStudent = lazy(() => import("./views/student/Account/payment/PaymentInfor"));

const MyTutors = lazy(() => import("./views/student/Account/tutors/MyTutors"));

const MyQuestions = lazy(() => import("./views/student/Account/questions/Questions"));

const MyLessonStudent = lazy(() => import("./views/student/Account/lessons/MyLessons"));

const OnlineLessonStudent = lazy(() => import("./views/student/Account/onlineLesson/OnlineLessonStudent"));

const OnlineLessonsStudent = lazy(() => import("./views/student/Account/onlineLesson/OnlineLessonsStudent"));

const SettingStudent = lazy(() => import("./views/student/Account/setting/SettingStudent"));

const MyTransactionsStudent = lazy(() => import("./views/student/Account/transaction/MyTransactions"));

const DashboardTutor = lazy(() => import("./views/Tutor/dashboard/DashboardTutor"));

const chatTutor = lazy(() => import("./views/Tutor/messages"));

const SettingInforTutor = lazy(() => import("./views/Tutor/settingTutor/SettingInforTutor"));

const Schedule = lazy(() => import("./views/Tutor/Lessons/Calender/Schedule"));

const scheduleLesson = lazy(() => import("./views/Tutor/Lessons/scheduleLesson/MyScheduleLessons"));

const SubmiteLesson = lazy(() => import("./views/Tutor/Lessons/submitLesson/SubmiteLesson"));

const OnlineLessonList = lazy(() => import("./views/Tutor/Lessons/onlineLesson/OnlineLessonList"));

const OnlineLesson = lazy(() => import("./views/Tutor/Lessons/onlineLesson/OnlineLesson"));

const PaymentInfoTutor = lazy(() => import("./views/Tutor/Accout/payment/PaymentInfoTutor"));

const ProfileTutor = lazy(() => import("./views/Tutor/Accout/profile/Profile"));

const SettingTutor = lazy(() => import("./views/Tutor/Accout/setting/Setting"));

const SearchQuestions = lazy(() => import("./views/Tutor/Accout/Questions/SearchQuestions"));

const QuestionDetail = lazy(() => import("./views/Tutor/Accout/Questions/Question"));

const SearchJobs = lazy(() => import("./views/Tutor/Accout/jobs/SearchJobs"));

const JobDetail = lazy(() => import("./views/Tutor/Accout/jobs/Job"));

const TutorTransactions = lazy(() => import("./views/Tutor/Accout/transaction/TutorTransactions"));

const MyStudents = lazy(() => import("./views/Tutor/Accout/students/MyStudents"));

const MyAnswers = lazy(() => import("./views/Tutor/Accout/answers/Answers"));

const LoadingPage = () => <div className="loader"></div>;

const App = () => {
  const auth = useSelector((state) => state.firebase.auth);
  const firebase = useFirebase();

  function rtdb_and_local_fs_presence() {
    // Fetch the current user's ID from Firebase Authentication.
    var uid = firebase.auth().currentUser.uid;

    // Create a reference to this user's specific status node.
    // This is where we will store data about being online/offline.
    var userStatusDatabaseRef = firebase.database().ref("/status/" + uid);
    var userStatusFirestoreRef = firebase.firestore().doc("/users/" + uid);
    // We'll create two constants which we will write to
    // the Realtime database when this device is offline
    // or online.
    var isOfflineForDatabase = {
      state: "offline",
      last_changed: firebase.database.ServerValue.TIMESTAMP,
    };

    var isOnlineForDatabase = {
      state: "online",
      last_changed: firebase.database.ServerValue.TIMESTAMP,
    };
    // Firestore uses a different server timestamp value, so we'll
    // create two more constants for Firestore state.
 

    var isOnlineForFirestore = {
      state: "online",
      last_changed: firebase.firestore.FieldValue.serverTimestamp(),
    };

    // Create a reference to the special '.info/connected' path in
    // Realtime Database. This path returns `true` when connected
    // and `false` when disconnected.
    firebase
      .database()
      .ref(".info/connected")
      .on("value", function (snapshot) {

        // If we are currently connected, then use the 'onDisconnect()'
        // method to add a set which will only trigger once this
        // client has disconnected by closing the app,
        // losing internet, or any other means.
        userStatusDatabaseRef
          .onDisconnect()
          .set(isOfflineForDatabase)
          .then(function () {
            // The promise returned from .onDisconnect().set() will
            // resolve as soon as the server acknowledges the onDisconnect()
            // request, NOT once we've actually disconnected:
            // https://firebase.google.com/docs/reference/js/firebase.database.OnDisconnect

            // We can now safely set ourselves as 'online' knowing that the
            // server will mark us as offline once we lose connection.
            userStatusDatabaseRef.set(isOnlineForDatabase);
            userStatusFirestoreRef.update(isOnlineForFirestore);
          });
      });
  }

  function setPresence(state) {
    if (firebase.auth().currentUser !== null) {
      var uid = firebase.auth().currentUser.uid;

      // Create a reference to this user's specific status node.
      // This is where we will store data about being online/offline.
      var userStatusDatabaseRef = firebase.database().ref("/status/" + uid);
      var userStatusFirestoreRef = firebase.firestore().doc("/users/" + uid);
      // We'll create two constants which we will write to
      // the Realtime database when this device is offline
      // or online.
      var isStateForDatabase = {
        state: state,
        last_changed: firebase.database.ServerValue.TIMESTAMP,
      };

      // Firestore uses a different server timestamp value, so we'll
      // create two more constants for Firestore state.
      var isStateForFirestore = {
        state: state,
        last_changed: firebase.firestore.FieldValue.serverTimestamp(),
      };

      userStatusDatabaseRef.set(isStateForDatabase);
      userStatusFirestoreRef.update(isStateForFirestore);
    }
  }

  if (!auth.isEmpty) {
    rtdb_and_local_fs_presence();
    document.onvisibilitychange = (e) => {
      if (document.visibilityState === "hidden") {
        setPresence("away");
      } else {
        setPresence("online");
      }
    };
  }

  return (
    <ThemeProvider theme={theme}>
      <Suspense fallback={<LoadingPage />}>
        <MetaDecorator
          title="TutorCarter: Find Private Tutors at Affordable Prices, In-Person &amp; Online"
          description="TutorCarter is the place where Tutors and Students can meet for online or in-person learning."
        />
        <ScrollTopButton />
        <BrowserRouter>
          <div className="App">
            <Navbar />
            <Switch>
              <Route exact path="/" component={Home} />
              {/* for common route */}
              <Route exact path="/signup" component={SignUp} />
              <Route exact path="/selectAccountType" component={SelectAccountType} />
              <Route exact path="/signup/:accountType" component={SignUp} />
              <Route exact path="/Tutor-lp1" component={TutorLp} />
              <Route exact path="/search-lp" component={SearchLp} />
              <Route exact path="/search-lp/:subject" component={SearchLp} />
              <Route exact path="/signIn" component={SignIn} />
              <Route exact path="/forgetPw" component={ForgetPw} />
              <Route exact path="/studentVerify" component={StudentVerify} />
              <Route exact path="/tutorVerify" component={TutorVerify} />
              <Route exact path="/resetPassword" component={ResetPw} />
              <Route exact path="/verification" component={Verify} />
              <Route exact path="/endsignup" component={EndSignUp} />
              <Route exact path="/howitworks" component={HowItWork} />
              <Route exact path="/howitworks/:category" component={HowItWork} />
              <Route
                exact
                path="/howitworksStudent"
                component={HowItWorkStudent}
              />
              <Route exact path="/howitworksTutor" component={HowItWorkTutor} />
              <Route exact path="/terms" component={Terms} />
              <Route exact path="/aboutus" component={AboutUs} />
              <Route exact path="/privacy" component={Privacy} />
              <Route exact path="/contactus" component={ContactUs} />
              <Route exact path="/posts" component={Posts} />
              <Route exact path="/post/:slug" component={PostView} />
              <Route exact path="/error" component={ErrorPage} />

              {/*            for student                  */}
              <Route
                exact
                path="/dashboardStudent"
                component={DashboardStudent}
              />
              <Route exact path="/searchTutor" component={SearchTutor} />
              <Route
                exact
                path="/tutorDetail/:tutorId"
                component={TutorDetail}
              />

              <Route
                exact
                path="/settingInforStudent"
                component={SettingInforStudent}
              />

              <Route
                exact
                path="/settingInforStudent/:search"
                component={SettingInforStudent}
              />
              <Route
                exact
                path="/privateRequest"
                component={PrivateRequestTutor}
              />
              <Route
                exact
                path="/privateRequest/:subject"
                component={PrivateRequestTutor}
              />
              <Route
                exact
                path="/helpRequest"
                component={RequestHelp}
              />
              <Route
                exact
                path="/helpRequest/:subject"
                component={RequestHelp}
              />
              <Route exact path="/requestHelp" component={RequestHelpWithSignin} />
              <Route exact path="/requestHelpFinish" component={RequestHelpFinish} />
              
              <Route exact path="/requestTutor" component={RequestTutor} />
              
              <Route
                exact
                path="/requestTutor/:tutorId"
                component={RequestTutor}
              />
              <Route
                exact
                path="/RequestTutorFinish"
                component={RequestTutorFinish}
              />
              <Route
                exact
                path="/RequestTutorFinish/:tutorId"
                component={RequestTutorFinish}
              />
              <Route exact path="/chatStudent" component={chatStudent} />
              <Route
                exact
                path="/chatStudent/:tutorId"
                component={chatStudent}
              />

              {/* Account part of student */}
              <Route
                exact
                path="/paymentInfor"
                component={PaymentInfoStudent}
              />
              <Route exact path="/myquestions" component={MyQuestions} />
              <Route exact path="/myTutorlist" component={MyTutors} />
              <Route exact path="/myLessonlist" component={MyLessonStudent} />
              <Route
                exact
                path="/myLessonlist/:search"
                component={MyLessonStudent}
              />
              <Route
                exact
                path="/onlineLessonStudent"
                component={OnlineLessonStudent}
              />
              <Route
                exact
                path="/onlineLessonsStudent"
                component={OnlineLessonsStudent}
              />
              <Route exact path="/settingStudent" component={SettingStudent} />
              <Route
                exact
                path="/myTransactionlist"
                component={MyTransactionsStudent}
              />

              {/* for tutor */}
              <Route exact path="/DashboardTutor" component={DashboardTutor} />
              <Route exact path="/chatTutor" component={chatTutor} />
              <Route exact path="/chatTutor/:studentId" component={chatTutor} />

              <Route
                exact
                path="/settingInforTutor"
                component={SettingInforTutor}
              />
              <Route
                exact
                path="/settingInforTutor/:step"
                component={SettingInforTutor}
              />
              {/* Lessons Part */}
              <Route exact path="/calender" component={Schedule} />
              <Route exact path="/scheduleLesson" component={scheduleLesson} />
              <Route exact path="/submitLesson" component={SubmiteLesson} />
              <Route exact path="/onlineLessons" component={OnlineLessonList} />
              <Route exact path="/onlineLesson" component={OnlineLesson} />
              {/* Account part of tutor */}
              <Route
                exact
                path="/paymentInfoTutor"
                component={PaymentInfoTutor}
              />
              <Route exact path="/profile" component={ProfileTutor} />
              <Route exact path="/profile/:category" component={ProfileTutor} />
              <Route exact path="/settings" component={SettingTutor} />
              <Route
                exact
                path="/jobs"
                component={SearchJobs}
              />
              <Route exact path="/job/:jobId" component={JobDetail} />
              
              <Route
                exact
                path="/questions"
                component={SearchQuestions}
              />
              <Route exact path="/question/:questionId" component={QuestionDetail} />
              
              <Route
                exact
                path="/tutorTransaction"
                component={TutorTransactions}
              />
              <Route exact path="/myStudents" component={MyStudents} />
              <Route exact path="/myanswers" component={MyAnswers} />
              <Route component={ErrorPage} />
            </Switch>
            <Footer />
          </div>
        </BrowserRouter>
      </Suspense>
    </ThemeProvider>
  );
};

export default App;
