import React from 'react'
import { Helmet } from 'react-helmet'

const MetaDecorator = (props) => {
    const { title, description } = props;
    
    return (
        <Helmet>
            <title> {title === null ? "" : title }</title>
            <meta name='description' content = {description === null ? "" : description} />
            <meta property ="og:title" content = {title === null ? "" : title } />
            <meta property ="og:description" content = {description === null ? "" : description } />
        </Helmet>
    )
}

export default MetaDecorator
