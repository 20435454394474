import * as actions from "../actions/actionTypes";

const initState = {
  notifications: [],
};
// HELPER FUNCTIONS

const notificationReducer = (state = initState, action) => {
  let _notifications = [];
  switch (action.type) {
    case "CLEAR_NOTIFY":
      return {
        ...state,
        notifications: [],
      };
    case "ADD_NOTIFY":
      _notifications = state.notifications;
      _notifications.push(action.payload);

      return {
        ...state,
        notifications: _notifications,
      };

    case "UPDATE_NOTIFY":
      _notifications = state.notifications;
      _notifications[action.payload.index] = {
        ..._notifications[action.payload.index],
        isRead: true,
      };

      return {
        ...state,
        notifications: _notifications,
      };

    default:
      return state;
  }
};

export default notificationReducer;
