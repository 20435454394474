const initState = {
  uploadFiles: [],
  error: false,
  message: "",
};
// HELPER FUNCTIONS

const cleanUp = () => {
  return { uploadFiles: [], error: false, message: "" };
};

const uploadReducer = (state = initState, action) => {
  switch (action.type) {
    case "UPLOAD_CLEAN":
      return cleanUp();
    case "UPLOADED_SUCCESS":
      return {
        uploadFiles: action.payload,
      };

    case "UPLOADED_ERROR":
      return {
        uploadFiles: [],
        error: true,
        message: action.payload,
      };

    default:
      return state;
  }
};

export default uploadReducer;
