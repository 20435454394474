import { colors } from "@material-ui/core";

const white = "#FFFFFF";
const black = "#000000";

export default {
  black,
  white,
  primary: {
    contrastText: white,
    dark: "#0e61ea",
    main: "#4285F4",
    light: "#8bb4f8",
    highLight: "#ebf2ff"
  },
  secondary: {
    contrastText: white,
    dark: "#ba2e21",
    main: "#db4437",
    light: "#e57a71",
  },
  error: {
    contrastText: white,
    dark: "#ba2e21",
    main: "#db4437",
    light: "#e57a71",
  },
  accept: {
    contrastText: white,
    dark: "#0c7e46",
    main: "#0f9d58",
    light: "#13c46e"
  },
  text: {
    primary: colors.blueGrey[900],
    secondary:colors.blueGrey[600],
    link: colors.blue[600],
  },
  link: colors.blue[800],
  icon: colors.blueGrey[600],
  background: {
    default: "#F4F6F8",
    paper: white,
  },
  divider: colors.grey[200],
};
